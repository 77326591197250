@import url("https://fonts.cdnfonts.com/css/nunito");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
   font-family: "Nunito", sans-serif;
}

html {
   --main-white: white;
   --main-black: #242424;
   --main-green: #00ca90;
   --main-green-o: rgba(168, 229, 197, 0.9);
   --blue: #70829c;
   --blue-o: rgb(112 130 156 / 0.27);
}

body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: var(--main-black) !important;
   min-height: 100vh;
   background-color: rgb(252, 252, 252) !important;
}

#root {
   min-height: inherit;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}
