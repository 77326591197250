.menu-dropdown {
   background-color: var(--main-white) !important;
   border: none;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
   padding: 0;
   overflow: hidden;
}

.item_menu {
   display: block;
   padding: 0.5em 0.9em;
   color: var(--main-black);
}

.item_menu:hover {
   display: block;

   color: rgb(255, 255, 255);
   background-color: #54c78c;
}
