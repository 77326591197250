.container-custom {
   max-width: 100% !important;
}

.home,
.rsa {
   min-height: 87.86vh;
}

.header {
   transition: all 0.5s;
   z-index: 997;
   background-color: var(--main-black);
}

.header.header-scrolled {
   background: #fff;
   padding: 15px 0;
   box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header .logo {
   line-height: 0;
}

.header .logo img {
   max-height: 40px;
   margin-right: 6px;
}

.header .logo span {
   font-size: 30px;
   font-weight: 700;
   letter-spacing: 1px;
   color: var(--main-green);
   font-family: "Nunito", sans-serif;
   margin-top: 3px;
}

h1 {
   font-weight: 800 !important;
   letter-spacing: -0.09rem;
   line-height: 3.6rem;
}

.hero-img {
   height: auto;
   width: 50vw;
}

.img--container {
   width: 90vw;
}

.btn-dark {
   background-color: var(--main-black);
}

.cards {
   background-color: var(--main-green);
}

.card {
   height: 30rem;
   overflow: hidden;
}

.card2 {
   height: 24rem;
   overflow: initial;
}

.badgeWrapper {
   margin-top: -3rem;
}

.badgeIcon2 {
   background-color: var(--main-green);
   width: 6rem;
}

.badgeIcon {
   width: 100%;
   height: 15rem;
   object-fit: cover;
   object-position: top;
}

.cta {
   margin-top: -.3rem !important;
}

.footer {
   background: var(--main-black);
   /* padding: 0 0 30px 0; */
   font-size: 0.72rem;
}


/* .footer .footer-top {
   background-size: contain;
   border-top: 1px solid #e1ecff;
   border-bottom: 1px solid #e1ecff;
   padding: 60px 0 30px 0;
} */

@media (min-width: 1200px) {
   .hero--content {
      border-left: 3px solid var(--main-black);
   }

   h1 {
      font-size: 3.9rem !important;
   }
}

@media (max-width: 1200px) and (min-width: 768px) {
   .hero--content {
      text-align: center;
   }

   .hero--content h1 {
      font-size: 2.7rem !important;
      line-height: 2.7rem;
   }

   .hero--content p {
      font-size: 0.6rem !important;
   }

   .img--container {
      width: 100%;
   }

   .hero-img {
      height: auto;
      width: 100%;
   }

   .container-custom {
      max-width: 90% !important;
   }

   /* footer {
      position: initial;
      width: 100%;
   } */
   .card {
      height: 24rem;
      margin-top: 3rem !important;
   }
}

@media (max-width: 576px) {
   .hero-img {
      margin-top: 3rem;
      min-width: 90%;
   }

   h1 {
      font-size: 3.3rem;
      line-height: 3.15rem;
      font-weight: 800 !important;
      letter-spacing: -0.06rem;
   }

   .card {
      margin-top: 6rem !important;
      height: 24rem;
   }
}


/* 
@media (max-width: 992px) {
   .footer .footer-top {
      background-position: center bottom;
   }
}

.footer .footer-top .footer-info {
   margin-bottom: 30px;
}

.footer .footer-top .footer-info .logo {
   line-height: 0;
   margin-bottom: 15px;
}

.footer .footer-top .footer-info .logo img {
   max-height: 40px;
   margin-right: 6px;
}

.footer .footer-top .footer-info .logo span {
   font-size: 30px;
   font-weight: 700;
   letter-spacing: 1px;
   color: #54c78c;
   font-family: "Nunito", sans-serif;
   margin-top: 3px;
}

.footer .footer-top .footer-info p {
   font-size: 14px;
   line-height: 24px;
   margin-bottom: 0;
   font-family: "Nunito", sans-serif;
}

.footer .footer-top .social-links a {
   font-size: 20px;
   display: inline-block;
   color: rgba(1, 112, 7, 0.5);
   line-height: 0;
   margin-right: 10px;
   transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
   color: #54c78c;
}

.footer .footer-top h4 {
   font-size: 16px;
   font-weight: bold;
   color: #54c78c;
   text-transform: uppercase;
   position: relative;
   padding-bottom: 12px;
}

.footer .footer-top .footer-links {
   margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
   list-style: none;
   padding: 0;
   margin: 0;
}

.footer .footer-top .footer-links ul i {
   padding-right: 2px;
   color: #d0d4fc;
   font-size: 12px;
   line-height: 0;
}

.footer .footer-top .footer-links ul li {
   padding: 10px 0;
   display: flex;
   align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
   padding-top: 0;
}

.footer .footer-top .footer-links ul a {
   color: #54c78c;
   transition: 0.3s;
   display: inline-block;
   line-height: 1;
}

.footer .footer-top .footer-links ul a:hover {
   color: #54c78c;
}

.footer .footer-top .footer-contact p {
   line-height: 26px;
}*/

.footer .copyright {
   text-align: center;
   color: var(--main-green);
   margin: 0;
}

.text-secondary {
   color: #70829c;
}

.step--container {
   width: 100%;
}

.phone-screen {
   width: 300px;
   height: 600px;
   border: var(--main-green-o) solid;
   border-width: 10px 10px;
   border-radius: 2.4em;
}

.btn-primary-s:active,
.btn-primary-s:focus-visible {
   background-color: #54c78c !important;
   color: #fff !important;
}

.btn-primary-s {
   background-color: #54c78c;
   border: 0;
   color: #fff;
}

.btn-danger-s {
   background-color: #f10b0b;
   border: 0;
   color: #fff;
}

.btn-primary-s:hover {
   background-color: #54c78cb0;
   border: 0;
   color: #fff;
}

.btn-danger-s:hover {
   background-color: #c50101;
   border: 0;
   color: #fff;
}

.form-control,
.form-select {
   border-radius: 6px;
   border: 1px solid var(--border, #e3e3e3);
   background: var(--main-white, #fff);
}


/* .form-control:active,
.form-select:active {
  border: solid 1px #54c78c !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px !important;
} */

.form-control:focus,
.form-select:focus {
   border: solid 1px #54c78c !important;
   box-shadow: #54c78c57 0px 0px 0px 3px !important;
}

.form-control-error,
.form-select-error {
   border: solid 1px #e43b25 !important;
   box-shadow: rgba(231, 11, 3, 0.341) 0px 0px 0px 3px !important;
}

.bg-success {
   background-color: #54c78c !important;
}

.bg-secondary {
   background-color: #e3e3e3 !important;
}

.message--error {
   color: red;
}

.login--page {
   position: relative;
   top: 10%;
   width: 90%;
}

@media screen and (min-height: 100px) {
   .register--page {
      min-height: 100vh !important;
   }
}

@media only screen and (max-width: 600px) {
   .register--page {
      min-height: 100vh !important;
   }

   .register--page__content {
      padding: 0 10px !important;
      margin: 0;
   }

   .register--page__content--main {
      margin: 0 !important;
   }

   .card {
      height: auto;
   }
}

@media (min-width: 900px) {
   .login--page {
      position: relative;
      top: 20%;
   }
}

@media only screen and (max-width: 600px) {
   .register--page {
      min-height: 100%;
   }

   .card {
      height: auto !important;
   }
}


/* Scrollbar Styling */

body::-webkit-scrollbar {
   width: 10px;
}

body::-webkit-scrollbar-track {
   background-color: var(--main-black);
   -webkit-border-radius: 10px;
   border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
   -webkit-border-radius: 10px;
   border-radius: 10px;
   background: #7ecea5;
}


/*Range Drag bar*/

.drag__bar::-webkit-slider-runnable-track {
   -webkit-appearance: none;
   background-color: #70829c71;
   border-radius: 5px;
   height: 8px;
   cursor: grab;
}

.drag__bar::-webkit-slider-thumb {
   appearance: none;
   -webkit-appearance: none;
   width: 60px;
   background: #7ecea5;
}

span {
   color: inherit;
}


/* footer {
   position: absolute;
   bottom: 0;
   width: 100%;
} */

.step--wrapper {
   height: 2.4rem !important;
   min-height: 2.4rem !important;
   width: 2.4rem !important;
   min-width: 2.4rem !important;
   border-radius: 50%;
   background-color: var(--main-green);
   font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
   display: flex;
   justify-content: center;
   align-items: center;
}

.font-cx {
   font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.green-bg-o {
   background-color: var(--main-green-o);
}

textarea::placeholder {
   /* color: red; */
   opacity: 0.3;
}

.blue {
   color: var(--blue);
   font-weight: 500;
   font-family: "Roboto";
}

textarea::placeholder {
   color: var(--blue-o) !important;
}

.fs-7 {
   font-size: 0.96rem;
}

.btn--headline {
   width: 6rem;
}

@media screen and (max-width: 768px) {
   .img--container {
      width: 100%;
   }

   .step--container {
      width: initial;
   }

   textarea::placeholder {
      padding: 0.3rem 0.42rem;
   }

   .bordered {
      border-right: none;
   }

   .container-custom {
      width: 100%;
   }

   .card {
      margin-top: 3rem !important;
      height: 33rem;
   }
}

@media screen and (min-width: 769px) {
   .bordered {
      border-right: 1px solid var(--bs-border-color);
   }

   .container-custom {
      max-width: 81% !important;
   }

   .card {
      height: 30rem;
   }

   .card2 {
      height: 21rem;
   }
}


/*Toggle button color set*/

.icon-label svg {
   stroke: #00ca90;
   fill: #00ca90;
}

.btn-check:checked+.icon-label svg {
   stroke: white;
   fill: white;
}

.-positionIco-top {
   position: relative;
   bottom: 2px;
}

svg {
   margin: 5px;
}

.shadow-rsa:hover {
   box-shadow: #00ca91b7 0px 1px 2px 0px, #00ca9179 0px 2px 6px 2px;
}

.btn-rsa {
   background-color: var(--main-green);
   font-weight: bold;
}

.btn-rsa:hover {
   background-color: #01e6a5;
}

.btn-rsa:focus {
   background-color: #01e6a5;
   box-shadow: #00ca9156 0px 1px 2px 0px, #00ca9179 0px 2px 6px 2px;
}

.btn-rsa:active {
   border: solid 1px var(--main-green) !important;
   background-color: #09ba88 !important;
}

.login--page__buttons--google {
   width: 100%;
}

.login--page__buttons--google>div {
   width: 100%;
   margin-top: 18px;
   cursor: pointer;
}

.register--page__content__card--height {
   height: 48rem;
}

@media screen and (min-width: 1000px) {
   .register--page__content__card--height {
      height: 36rem;
   }
}

.register--page__content__button--width {
   width: 90%;
}


@media screen and (min-width: 1000px) {
   .register--page__content__button--width {
      width: 50%;
      position: relative;
      left: 25%;
   }
}